import styled from "styled-components";

const FooterStyle = styled.footer``
function Footer() {
    return (
        <FooterStyle>
            TODO FOOTER
            Return to top button
        </FooterStyle>
    );
}

export default Footer;